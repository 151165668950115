/**
Mobile insurance colors
*/
$mobile_insurance-color: #ff7900;
$mobile_insurance_dark-color: #f16e00;
$mobile_insurance_light-color: #ff7900;
$mobileInsuranceBlue: #527edb;
$mobileInsuranceGray-color: #ffffff;
$mobileInsuranceBlack-color: #000000;
$mobileInsuranceBlackLight-color: #999999;
// $color: rgba(221, 221, 221, .8);
$color: #ff7900;
$passed: #333333;
$active: #ff7900;
$warning: #ed6c02;
$black-color: #000000;
$primary-color: #ff7900;
$secondary-color: #f16e00;
$primary-border: 1px solid #dddddd;

$primary-text: white;
$secondary-text: black;
