.MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    display: block;
    // margin-top: 3px;
    // margin-right: 14px;
    // margin-bottom: 0;
    // margin-left: 14px;
}
.Mui-error fieldset {
    background-color: rgb(211 47 47 / 6%);
}
#selectedLanguage.MuiOutlinedInput-input {
    animation-name: none !important;
    padding: 5px !important;
    background-color: #ffffff; /* A hátteret fehérre állítja */
}
#pageSizeSelector.MuiOutlinedInput-input {
    animation-name: none !important;
    padding: 5px 10px !important;
    width: 50px;
    background-color: #ffffff; /* A hátteret fehérre állítja */
}
.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 !important;
}
