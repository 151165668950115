.MuiButton-root.Mui-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: 1.5rem auto;
}

.primary-btn.MuiButton-root.MuiButton-sizeMedium {
    background-color: $secondary-color !important;
    color: $primary-text !important;
    a {
        color: $primary-text !important;
    }
}
.secondary-btn {
    background-color: $secondary-color;
}
.btn {
    border-radius: 0 !important;
}
.base-btn {
    border: 3px solid $mobile_insurance-color !important;
    color: $mobile_insurance-color !important;
    width: fit-content !important;
}
.black-btn {
    background-color: $mobileInsuranceBlack-color !important;
    color: $primary-text !important;
    width: fit-content !important;
    &-border {
        width: fit-content !important;
        background-color: transparent;
        border: 3px solid $black-color !important;
        color: black !important;
    }
}
.new-claim__button .btn {
    font-weight: 700;
    width: fit-content;
}
