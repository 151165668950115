.product-step__grey-group_block {
    transition: all 0.2s ease;
    animation: flashing 2s linear infinite;
}
@keyframes flashing {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}
